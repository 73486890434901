import React  from "react";
import { Link } from 'react-router-dom';

// function useInterval(callback, delay) {
//     const savedCallback = useRef();
//
//     // Remember the latest callback.
//     useEffect(() => {
//         savedCallback.current = callback;
//     }, [callback]);
//
//     // Set up the interval.
//     useEffect(() => {
//         function tick() {
//             savedCallback.current();
//         }
//         if (delay !== null) {
//             let id = setInterval(tick, delay);
//             return () => clearInterval(id);
//         }
//     }, [delay]);
// }

function Home() {
  //let [count, setCount] = useState(0);

  // useInterval(() => {
  //     setCount(count + 1);
  // }, 5000);

  return(
    <div className="homepage-container">
      <div className="title-container">
          <h1 className="title">TreeMaTech</h1>
          <h2 className="sub_title">Leaders in Biorenewables</h2>
          <div className="button-container">
          <Link className="action-button noselect" title="view products" to={"/products"}>Explore our products</Link>
        </div>
      </div>
      <video className="bg-video" poster={require('../../../assets/bg.jpg')} preload={"auto"} autoPlay={true} loop={true} muted={true} playsInline={true}>
        <source src={require('../../../assets/bg.webm')} type="video/webm"/>
        <source src={require('../../../assets/bg.mp4')} type="video/mp4"/>
      </video>
      <div className="gradient"/>
    </div>
  );
}

export default Home;
