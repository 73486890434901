import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return(
    <div className="footer-container">
      <div className="sub-container">
        <div>
          <Link to={"/"} className="homelink">
            <img className="logo" src={require('../../../assets/logo.svg')} alt="logo"/>
            <h1 className="title noselect">TreeMaTech</h1>
          </Link>
          <p className="text">Leaders in Biorenewables</p>
        </div>
        <div className="footer-links">
          <div className="links-list">
              <Link to={"/about"} className="footer-link">About</Link>
              <Link to={"/news"} className="footer-link">News</Link>
              <Link to={"/contact"} className="footer-link">Contact</Link>
          </div>
          <div className="links-list">
              <Link to={"/products/treeyon"} className="footer-link">Treeyon</Link>
              <Link to={"/products/cellophax"} className="footer-link">Cellophax</Link>
              <Link to={"/products/hnc"} className="footer-link">HNC</Link>
          </div>
          <div className="links-list">
              <Link to={"/products/derivatives"} className="footer-link">Derivatives</Link>
              <Link to={"/products/hydrogel"} className="footer-link">Hydrogel</Link>
              <Link to={"/products/other"} className="footer-link">Other</Link>
          </div>
          {/*<div className="links-list">*/}
          {/*    <Link to={"/"} className="footer-link">Français *À venir</Link>*/}
          {/*    <Link to={"/sitemap"} className="footer-link">Site&nbsp;Map</Link>*/}
          {/*</div>*/}
        </div>
      </div>
      <div className="footer-bottom">
        <div className="bar"/>
        <p className="copyright">Copyright © 2020 TreeMaTech Inc. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
