import React from "react";
import { Link } from 'react-router-dom';

function LatestArticle(props) {
    let articleTitle = props.latestArticle.title.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ").toLowerCase().split(' ').join('_');
    return (
      <Link to={`/news/${articleTitle}/${props.latestArticle.id}`} className="latest-container">
        <div className="latest-article">
          <div className="latest-header-continers">
            <h3 className="news-header news-title">{props.latestArticle.title}</h3>
            <p className="news-header news-date">{props.latestArticle.date}</p>
          </div>
          <p style={{margin: '10px 0 20px 0'}}>{props.latestArticle.intro} <span className="more-link">more</span></p>
          <img className="latest-image" src={require(`../../../../assets/articles/${props.latestArticle.image}`)} alt="news" />
        </div>
      </Link>
    );
}

export default LatestArticle;
