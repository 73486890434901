import React from 'react';

function Row(props) {
  return(
    <div className="row-bg">
      <div className="row-container about-pictures">
        <img className="headshot" src={require(`../../../../assets/headshots/${props.employee.picture}`)} alt={props.employee.name}/>
        <div style={{overflow: 'hidden'}}>
          <h3 className="name">{props.employee.name}</h3>
          <p className="text">{props.employee.title}</p>
          <p className="text">{props.employee.description}</p>
          <div className="link-container">
            <img className="icon nodrag noselect" src={require('../../../../assets/world.svg')} alt="website"/>
            <a className="url" href={props.employee.website} target="blank" rel="noopener">{props.employee.website}</a>
          </div>
          <div className="link-container">
            <img className="icon nodrag noselect" src={require('../../../../assets/email.svg')} alt="email"/>
            <a href={`mailto: ${props.employee.email}?subject= TreeMaTech - [Subject]`} className="url" rel="noopener">{props.employee.email}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

function EmployeeList(props) {
  return(
    <div className="employees-container">
      {props.employees.data.map((employee, index) =>
          <Row key={index} employee={employee} />
        )
      }
    </div>
  );
}

export default EmployeeList;
