import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { default as employees } from '../../../data/en/employees.json';

//components
import Header from '../containers/Header';
import About from '../containers/About';
import Footer from '../containers/Footer';

function AboutPage({location: { pathname }}) {
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return(
    <div>
      <Helmet>
          <title>TreeMaTech – About</title>
          <meta name="description" content="We envisage living on a sustainable planet, free of plastic waste and other forms of pollution without jeopardizing our standard of living."/>
          <meta property="og:title" content="TreeMaTech - About"/>
          <meta property="og:description" content="We envisage living on a sustainable planet, free of plastic waste and other forms of pollution without jeopardizing our standard of living."/>
          <meta property="og:url" content="https://treematech.com/about"/>
          <meta property="og:image" content="https://treematech.com/static/media/cofounders.3b344730.jpg"/>
      </Helmet>
      <Header />
      <About employees={employees}/>
      <Footer />
    </div>
  );
}

export default withRouter(AboutPage);
