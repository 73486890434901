import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  }
  return(
    <div className="header-container">
      <div className="header">
        <Link to={"/"} className="homelink">
          <img className="logo nodrag" src={require('../../../assets/logo.svg')} alt="logo"/>
          <h1 className="title">TreeMaTech</h1>
        </Link>
        <div className="links nodrag">
          <Link className="link" to={"/about"}>About</Link>
          <Link className="link" to={"/products"}>Products</Link>
          <Link className="link" to={"/news"}>News</Link>
          <Link className="link" to={"/contact"}>Contact</Link>
        </div>
        <div className="hamburger nodrag" onClick={handleMenuClick}>
          <div className="bar"/>
          <div className="bar"/>
          <div className="bar"/>
        </div>
      </div>
      <div className="header-line"/>
      <div className={isOpen ? "mobile-header" : "no-header"}>
        <Link className="link" to={"/about"}>About</Link>
        <Link className="link" to={"/products"}>Products</Link>
        <Link className="link" to={"/news"}>News</Link>
        <Link className="link" to={"/contact"}>Contact</Link>
      </div>
    </div>
  );
}

export default Header;
