import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

//components
import Header from '../containers/Header';
import Home from '../containers/Home';

function HomePage({location: { pathname }}) {
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return(
    <div>
      <Helmet>
          <title>TreeMaTech – Leaders in Biorenewables</title>
          <meta name="description" content="TreeMaTech is a world leader in renewable and biodegradable cellulose-based materials. We assist industries by providing innovative, green solutions to their problems."/>
          <meta property="og:title" content="TreeMaTech - Leaders in Biorenewables" />
          <meta property="og:description" content="TreeMaTech is a world leader in renewable and biodegradable cellulose-based materials. We assist industries by providing innovative, green solutions to their problems."/>
          <meta property="og:url" content="https://treematech.com/"/>
          <meta property="og:image" content="https://treematech.com/og_bg.jpg"/>
      </Helmet>
      <Header />
      <Home />
    </div>
  );
}

export default withRouter(HomePage);
