import React from 'react';

//components
import ImageGallery from './ImageGallery';

function ProductInfo(props) {
  return (
    <div className="details">
      {typeof props.product.description !== "undefined" &&
        <React.Fragment>
          <h2  className="features">Description</h2>
          <p className="product-paragraph">{props.product.description}</p>
        </React.Fragment>
      }
      {typeof props.product.features !== "undefined" &&
        <React.Fragment>
          <h2>Features</h2>
          <ul>
            {props.product.features.map((feature, index) =>
                <li key={index}>{feature.text}</li>
              )
            }
          </ul>
        </React.Fragment>
      }
    </div>
  );
}

function ProductDetails(props) {
  return(
    <div className="product-details-container">
      {/*{typeof props.product.headerVideo !== "undefined" &&*/}
      {/*  <div className="header-video-container">*/}
      {/*    <img className="video nodrag noselect" src={require(`../../../../assets/${props.product.headerVideo}`)} alt={props.product.name}/>*/}
      {/*  </div>*/}
      {/*}*/}
      <div className="details-container">
        <ImageGallery images={props.product.imageGallery} />
        <ProductInfo product={props.product}/>
      </div>
    </div>
  );
}

export default ProductDetails;
