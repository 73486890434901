import React from "react";
import { withRouter } from 'react-router-dom';

//components
function ProductCard(props) {
  return(
    <div className={props.index === 0 ? "sub-card first" : "sub-card"}>
      <div className="image-container">
        <img className="poduct-image noselect nodrag" src={require(`../../../../assets/${props.category}/${props.product.image}`)} alt={props.product.name}/>
      </div>
      <div className="sub-container">
        <div className="green-line"/>
        <h2 className="title">{props.product.name}</h2>
        <p className="sub-title">{props.product.subTitle}</p>
      </div>
    </div>
  );
}


function OtherProducts(props) {
  return(
    <div className="sub-card-container">
      {(typeof props.product['subProducts'] !== "undefined") &&
        <div>
          {props.product['subProducts'].map((subProducts, index) =>
              <ProductCard key={index.toString()} index={index} product={subProducts} category={props.match.params.productName}/>
            )
          }
        </div>
      }
    </div>
  );
}

export default withRouter(OtherProducts)
