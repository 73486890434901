import React from "react";
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function ArtcileInfo(props) {
  return(
    <div className="article-container">
      <Helmet>
          <title>TreeMaTech - {props.title}</title>
      </Helmet>
      {typeof props.articleInfo.contents !=="undefined" &&
        <div>
          {props.articleInfo.contents.map((content, index) =>
            <div key={index}>
              {typeof content.intro !== "undefined" ? (
                <p>{content.intro}</p>
              ) : typeof content.image !== "undefined" ? (
                <div className="image-container">
                  <img src={require(`../../../../assets/articles/${content.image}`)} alt={content.caption}/>
                  <div className="caption-container">
                    <div className="bar"/>
                    <p>{content.caption}</p>
                  </div>
                </div>
              ) : typeof content.paragraph !== "undefined" &&
                <p>{content.paragraph}</p>
              }
            </div>
          )}
        </div>
      }
      <div className="social-container">
        <a className="logo-container" title="Share on Facebook" href={`https://www.facebook.com/sharer/sharer.php?u=https://treematech.com${props.location.pathname}`} target="_blank" rel="noopener noreferrer">
          <img className="social-logo" src={require(`../../../../assets/articles/logo_facebook.png`)} alt="Facebook"/>
        </a>
        <a className="logo-container" title="Share on Twitter" href={`https://twitter.com/share?ref_src=https://treematech.com${props.location.pathname}">`} target="_blank" rel="noopener noreferrer">
          <img className="social-logo" src={require(`../../../../assets/articles/logo_twitter.png`)} alt="Twitter"/>
        </a>

        <a className="logo-container" title="Share on Linkedin" href={`https://www.linkedin.com/shareArticle?mini=true&url=https://treematech.com${props.location.pathname}`} target="_blank" rel="noopener noreferrer">
          <img className="social-logo" src={require(`../../../../assets/articles/logo_linkedin.png`)} alt="Linkedin"/>
        </a>
        <a className="logo-container" title="Share by Email" href={`mailto:?subject=TreeMaTech[News Article]&body=Check out this article https://treematech.com${props.location.pathname}`} rel="noopener noreferrer">
          <img className="social-logo" src={require(`../../../../assets/articles/email.png`)} alt="email"/>
        </a>
      </div>
    </div>
  );
}

export default withRouter(ArtcileInfo);
