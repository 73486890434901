import React from 'react';
import * as allNewsData from '../../../data/en/news/allNewsData';

//components
import LatestArticle from './components/LatestArticle';
import Statements from './components/Statements';
import NewsAndEvents from './components/NewsAndEvents';

function News(props) {
  let latestArticle = allNewsData["articlesPreviews"].data[allNewsData["articlesPreviews"].data.length-1];
  let articlePreviews = allNewsData["articlesPreviews"].data;

  return (
    <div className="page-bg">
      <div className="page-container">
        <h1 className="main-title nodrag">News</h1>
        <div className="news-headers">
          <LatestArticle latestArticle={latestArticle} />
          <Statements statements={allNewsData["inTheNews"].data} />
        </div>
        <NewsAndEvents articlePreviews={articlePreviews}/>
      </div>
    </div>
  );
}

export default News;
