import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

//components
import Header from '../containers/Header';
import News from '../containers/News';
import Footer from '../containers/Footer';

function ContactPage({location: { pathname }}) {
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return(
    <div>
      <Helmet>
          <title>TreeMaTech – News</title>
          <meta name="description" content="TreeMaTech news, events and publications"/>
          <meta property="og:title" content="TreeMaTech - News"/>
          <meta property="og:description" content="TreeMaTech news, events and publications"/>
          <meta property="og:url" content="https://treematech.com/news"/>
          <meta property="og:image" content="https://treematech.com/og_bg.jpg"/>
      </Helmet>
      <Header />
      <News />
      <Footer />
    </div>
  );
}

export default withRouter(ContactPage);
