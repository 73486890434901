import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as allProductData from '../../../data/en/allProductData';

//components
import ProductDetails from './components/ProductDetails';
import OtherProducts from './components/OtherProducts';
import ExtraProductInfo from './components/ExtraProductInfo';
//import NextProductBanner from './components/NextProductBanner';

function Product(props) {
  let productName = props.match.params.productName.toString();
  let product = allProductData[productName].data;
  let pageTitle = `TreeMaTech - ${allProductData.allProducts.data[props.match.params.productName][0].name}`;

  return(
    <div className="page-bg">
      <Helmet>
          <title>{pageTitle}</title>
      </Helmet>
      <div className="productList-container">
        <h1 className="main-title">{product.material}</h1>
        <ProductDetails product={product} />
        <OtherProducts product={product} />
        <ExtraProductInfo product={product} />
        {/*<NextProductBanner products={allProductData.allProducts.data[props.match.params.productName][0].products}/>*/}
      </div>
    </div>
  );
}

export default withRouter(Product);
