import React from "react";

function ImageSelector(props) {
    return(
        <React.Fragment>
          { props.images.length > 1 &&
            <div className="selector">
              {props.images.map((image) =>
                  <div key={image.id.toString()} className={props.images[props.count % props.images.length] === image ? "circle fill" : "circle"} onClick={() => props.handleCircleClick(image.id)}/>
              )}
            </div>
          }
        </React.Fragment>
    );
}

export default ImageSelector;
