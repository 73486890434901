import React, { useState } from 'react';

function Contact(props) {
  const [name, setName] = useState("");
  const [organization, setOrganization] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleFromSubmit = (e) => {
    e.preventDefault();
    let emailTo = "support@treematech.com";
    let sendingMessage = message.replace(/\r?\n/g, '%0D%0A');
    let body = sendingMessage + "%0D%0A%0D%0A---------------------------------------%0D%0AMessage sent by " + name + " from " + organization;
    window.location.href = "mailto:" + emailTo + "?subject=" + subject + "&body=" + body;
  }

  return(
    <div className="page-bg">
      <div className="page-container">
        <h1 className="main-title nodrag">Contact Us</h1>
        <p className="text nodrag">Invest in a future without plastic waste</p>
        <form className="sendEmail" encType ="multipart/form-data" onSubmit={handleFromSubmit}>
          <label className="form-label">Name</label>
          <input className="input_field nodrag" type="text" name="name" required onChange={(e) => setName(e.target.value)}/>
          <label className="form-label">Organization</label>
          <input className="input_field nodrag" type="text" name="organization" required onChange={(e) => setOrganization(e.target.value)}/>
          <label className="form-label">Email</label>
          <input className="input_field nodrag" type="email" name="email" required />
          <label className="form-label">Subject</label>
          <input className="input_field nodrag" type="text" name="subject" required onChange={(e) => setSubject(e.target.value)}/>
          <label className="form-label">Message</label>
          <textarea className="input_field nodrag" name="message" rows="5" onChange={(e) => setMessage(e.target.value)}></textarea>
          <button type="submit" name="submit" className="submit-button noselect nodrag" title="send email">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
