import React from 'react';

function Row(props) {
  return (
    <div className="image-container">
        <div className="img-subcontainer">
          <img className="img" src={require(`../../../../assets/${props.product.image}`)} alt={props.product.name}/>
        </div>
        <div>
          <div className="horizontal-line"/>
          <h2 className="info-header">{props.product.header}</h2>
          <p className="product-paragraph">{props.product.text}</p>
        </div>
    </div>
  );
}

function ExtraProductInfo(props) {
  return (
    <React.Fragment>
      {typeof props.product.moreInfo !== "undefined" &&
        <div className="extra-info-container">
          {props.product.moreInfo.map((row, index) =>
              <Row key={index.toString()} product={row} />
            )
          }
        </div>
      }
    </React.Fragment>
  );
}

export default ExtraProductInfo;
