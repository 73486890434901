import React, {useEffect, useState, useRef} from "react";

//components
import ImageSelector from "./ImageSelector";

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

function ImageGallery(props) {
    let [count, setCount] = useState(1000);

    useInterval(() => {
        setCount(count + 1);
    }, 7000);

    const handleCircleClick = (selector) => {
        setCount(selector);
    };

    const handleImageClick = () => {
        setCount(count + 1);
    };

    return(
        <React.Fragment>
          {typeof props.images !== "undefined" &&
          <div className="imageGallery">
              <div className="image-container" onClick={handleImageClick}>
                <img className="img" src={require(`../../../../assets/${props.images[count % props.images.length].image}`)} alt="produit"/>
              </div>
                <ImageSelector images={props.images} count={count} handleCircleClick={handleCircleClick}/>
          </div>
        }
        </React.Fragment>
    )
}
export default ImageGallery;
