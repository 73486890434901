import React from 'react';
import { default as sponsors } from '../../../../data/en/sponsors.json';

function Logo(props) {
  return(
    <div className="logo">
      <img className="image noselect nodrag" src={require(`../../../../assets/sponsors/${props.sponsor.logo}`)} alt="alt"/>
    </div>
  );
}

function Sponsors() {
  return (
    <React.Fragment>
      {typeof sponsors.data !== "undefined" &&
        <div className="logo-container">
          {
            sponsors.data.map((sponsor, index) =>
              <Logo key={index} sponsor={sponsor} />
            )
          }
        </div>
      }
    </React.Fragment>
  );
}

export default Sponsors;
