import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

//components
import Header from '../containers/Header';
import Article from '../containers/Article';
import Footer from '../containers/Footer';

function ArticlePage({location: { pathname }}) {
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return(
    <div>
      <Header />
      <Article />
      <Footer />
    </div>
  );
}

export default withRouter(ArticlePage);
