import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

//components
import Header from '../containers/Header';

function NoPageFound() {
  return(
    <div className="nopagefound-page">
      <Helmet>
          <title>TreeMaTech – Page not found</title>
      </Helmet>
      <Header />
      <img className="bg404" src={require('../../../assets/bg.jpg')} alt="bg"/>
      <div className="gradient"/>
      <div className="nopagefound-container">
        <h1 className="header404">404</h1>
        <h2 className="sub-header404">The page you are looking for was not found</h2>
        <Link to={"/"} className="link404">
          <div className="return404">
            <p>Return home</p>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default NoPageFound;
