import React from 'react';

//components
import EmployeeList from './components/EmployeeList';
import Sponsors from './components/Sponsors';

function About(props) {
  return(
    <div className="page-bg">
      <div className="page-container">
        <h1 className="main-title nodrag">About Us</h1>
        <p className="text nodrag">TreeMaTech is a world leader in renewable and biodegradable cellulose-based materials. We assist industries by providing innovative, green solutions to their&nbsp;problems.</p>
        <div className="about-video-container">
          <div style={{padding: '56.25% 0 0 0', position: 'relative'}}><iframe title="TreeMaTech Video" src="https://player.vimeo.com/video/353274707?color=58CC67&title=0&byline=0&portrait=0" style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', borderRadius: '10px 10px 0 0'}} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe></div>
          <div className="about-intro">
            <h3 className="about-header">Vision</h3>
            <p className="about-text">We envisage living on a sustainable planet, free of plastic waste and other forms of pollution without jeopardizing our standard of living.</p><br/>
            <h3 className="about-header">Mission statement</h3>
            <p className="about-text">To replace non-renewable, fossil fuel based materials with biorenewable alternatives. Thus, contributing to the gradual elimination of pollution and to a sustainable bioeconomy. We ensure that each production process is environmentally friendly and that all our products are fully biodegradable and recyclable.</p>
          </div>
        </div>
        <div className="banner-container">
          <div className="image-container">
            <img className="image noselect" src={require('../../../assets/cofounders.jpg')} alt="cofounders"/>
          </div>
          <div className="text-container">
            <div className="horizontal-line"/>
            <h3 className="title2 nodrag">Cofounders</h3>
            <p className="text2 nodrag">Jean-Philip Lumb, Theo van de Ven and Nur Alam collaborated for several years developing novel cellulosic products. Their research led to the discovery of several new products with a large range of applications. Together they founded TreeMaTech Inc. with the objective of bringing these products to the market.</p>
          </div>
        </div>
        <Sponsors />
        <EmployeeList employees={props.employees}/>
      </div>
    </div>
  );
}

export default About;
