import React from "react";
import { Link } from 'react-router-dom';

function Row(props) {
  let articleTitle = props.article.title.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ").toLowerCase().split(' ').join('_');

  return (
    <div className="row-bg">
      <Link to={`/news/${articleTitle}/${props.article.id}`} className="article-link-row">
        <div className="row-container news-container">
          <img className="article-preview-image" src={require(`../../../../assets/articles/${props.article.image}`)} alt="article_preview"/>
          <div>
            <h3 className="name news-title">{props.article.title}</h3>
            <p className="row-date">{props.article.date}</p>
            <p className="text">{props.article.intro} <span className="more-link">more</span></p>
          </div>
        </div>
      </Link>
    </div>
  );
}

function NewsAndEvents(props) {
  let articlePreviews = props.articlePreviews.slice(0, -1).reverse();

  return (
    <div className="employees-container">
      {articlePreviews.map((article, index) =>
          <Row key={index} article={article} />
        )
      }
    </div>
  );
}

export default NewsAndEvents;
