import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { default as materials } from '../../../data/en/materials.json';

function Material(props) {
  let productURL = props.material.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().split(' ').join('_');

  return(
    <Link to={`/products/${productURL}`} className="material-container nodrag noselect">
      <div className="image-container">
        <img className="image nodrag noselect" src={require(`../../../assets/materials/${props.material.image}`)} alt={props.material.name}/>
      </div>
      <div className="overlay">
        <h1 className="link title">{props.material.name}</h1>
      </div>
    </Link>
  );
}

function Materials() {
  let materialsRef = useRef()

  useEffect(() => {
      let mq = window.matchMedia("(max-width: 749px)");
      if(mq.matches && materialsRef.current !== null){
        window.addEventListener('scroll', handleScroll);
      }
  }, []);

  const handleScroll = () =>  {
    let mq = window.matchMedia("(max-width: 749px)");
    if (mq.matches && materialsRef.current) {
      let scrollMaxY = window.scrollMaxY || (document.documentElement.scrollHeight - document.documentElement.clientHeight);
        if (window.pageYOffset / scrollMaxY < 0.09) {
          materialsRef.current.childNodes[0].classList.add('active');
          materialsRef.current.childNodes[1].classList.remove('active');
        } else if (window.pageYOffset / scrollMaxY >= 0.09 && window.pageYOffset / scrollMaxY < 0.24) {
          materialsRef.current.childNodes[0].classList.remove('active');
          materialsRef.current.childNodes[1].classList.add('active');
          materialsRef.current.childNodes[2].classList.remove('active');
        } else if (window.pageYOffset / scrollMaxY >= 0.24 && window.pageYOffset / scrollMaxY < 0.39) {
          materialsRef.current.childNodes[1].classList.remove('active');
          materialsRef.current.childNodes[2].classList.add('active');
          materialsRef.current.childNodes[3].classList.remove('active');
        } else if (window.pageYOffset / scrollMaxY >= 0.39 && window.pageYOffset / scrollMaxY < 0.54) {
          materialsRef.current.childNodes[2].classList.remove('active');
          materialsRef.current.childNodes[3].classList.add('active');
          materialsRef.current.childNodes[4].classList.remove('active');
        } else if (window.pageYOffset / scrollMaxY >= 0.54 && window.pageYOffset / scrollMaxY < 0.69) {
          materialsRef.current.childNodes[3].classList.remove('active');
          materialsRef.current.childNodes[4].classList.add('active');
          materialsRef.current.childNodes[5].classList.remove('active');
        } else {
          materialsRef.current.childNodes[4].classList.remove('active');
          materialsRef.current.childNodes[5].classList.add('active');
        }
    }
  }

  return(
    <div className="page-bg">
      <div className="page-container">
        <h1 className="main-title nodrag">Products</h1>
        <div className="materials-container" ref={materialsRef}>
          {materials.data.map((material, index) =>
            <Material key={index} material={material} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Materials;
