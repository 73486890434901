import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

//components
import Header from '../containers/Header';
import Footer from '../containers/Footer';

function SiteMapPage({location: { pathname }}) {
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return(
    <div>
      <Helmet>
          <title>TreeMaTech – Site Map</title>
            <meta name="description" content="The site map of TreeMaTech"/>
            <meta property="og:title" content="TreeMaTech - Site Map"/>
            <meta property="og:description" content="The site map of TreeMaTech"/>
            <meta property="og:url" content="https://treematech.com/"/>
            <meta property="og:image" content="https://treematech.com/og_bg.jpg"/>
      </Helmet>
      <Header />
      <div className="page-bg">
        <div className="page-container">
          <h1 className="main-title nodrag">Site Map</h1>
          <div style={{marginTop: '15px'}}>
            <div className="sitemap-links">
              <h3 className="sitemap-header">About TreeMaTech</h3>
              <Link className="sitemap-link" to={"/about"}>About</Link>
              <Link className="sitemap-link" to={"/news"}>News</Link>
              <Link className="sitemap-link" to={"/contact"}>Contact</Link>
            </div>
            <div className="sitemap-links">
              <h3 className="sitemap-header">Products from TreeMaTech</h3>
              <Link className="sitemap-link" to={"/products"}>Product List</Link>
              <Link className="sitemap-link" to={"/products/treeyon"}>Treeyon</Link>
              <Link className="sitemap-link" to={"/products/cellophax"}>Cellophax</Link>
              <Link className="sitemap-link" to={"/products/hnc"}>HNC</Link>
              <Link className="sitemap-link" to={"/products/derivatives"}>Derivatives</Link>
              <Link className="sitemap-link" to={"/products/hydrogel"}>Hydrogel</Link>
              <Link className="sitemap-link" to={"/products/other"}>Other</Link>
            </div>
            {/*<div className="sitemap-links">*/}
            {/*  <h3 className="sitemap-header">Languages</h3>*/}
            {/*  <Link className="sitemap-link" to={"/"}>English</Link>*/}
            {/*  <Link className="sitemap-link" to={"/sitemap"}>Français *À venir</Link>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default withRouter(SiteMapPage);
