import React from 'react';
import { withRouter } from 'react-router-dom';
import * as allNewsData from '../../../data/en/news/allNewsData';

//components
import ArticleInfo from "./components/ArticleInfo";

function Article(props) {
  let articleInfo = allNewsData["articles"].data[props.match.params.articleID];
  let nextproductID = ((parseInt(props.match.params.articleID, 10) - 1) + allNewsData["articles"].data.length) % allNewsData["articles"].data.length;
  let nextArticle = allNewsData["articles"].data[nextproductID];
  console.log(nextArticle);
  return(
    <div className="page-bg">
      <div className="page-container">
        <h1 className="main-title nodrag">{articleInfo.title}</h1>
        <p className="news-date">{articleInfo.date}</p>
        <ArticleInfo articleInfo={articleInfo} title={articleInfo.title}/>
        {/*<div>
          <h2>{nextArticle.title}</h2>
          <p>{nextArticle.contents[0].intro} <span className="more-link">more</span></p>
        </div>*/}
      </div>
    </div>
  );
}

export default withRouter(Article);
