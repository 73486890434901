import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

//components
import Header from '../containers/Header';
import Materials from '../containers/Materials';
import Footer from '../containers/Footer';

function MaterialPage({location: { pathname }}) {
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return(
    <div>
      <Helmet>
          <title>TreeMaTech – Products</title>
          <meta name="description" content="Available products at TreeMaTech that are renewable and biodegradable."/>
          <meta property="og:title" content="TreeMaTech - Products"/>
          <meta property="og:description" content="Available products at TreeMaTech that are renewable and biodegradable."/>
          <meta property="og:url" content="https://treematech.com/products"/>
          <meta property="og:image" content="https://treematech.com/og_bg.jpg"/>
      </Helmet>
      <Header />
      <Materials />
      <Footer />
    </div>
  );
}

export default withRouter(MaterialPage);
