import React from "react";

function NewsLink(props) {
  return(
    <div className="news-link">
      <div className="vertical-line"/>
      <div style={{ wordBreak: 'break-word'}}>
        <p className="urlHeader">{props.statement.header}</p>
        {props.statement.links.map((link, index) =>
          <a key={index} href={link.url} target="blank" rel="noopener" className="url">{link.url}</a>
        )}
      </div>
    </div>
  )
}

function Statements(props) {
    return (
      <div className="statements">
        <h3 className="news-header" style={{margin: '0 0 20px 0'}}>In the News</h3>
        <div className="news-link-container">
          {props.statements.reverse().map((statement, index) =>
            <NewsLink key={index} statement={statement}/>
          )}
        </div>
      </div>
    );
}

export default Statements;
