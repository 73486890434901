import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

//components
import Header from '../containers/Header';
import Product from '../containers/Product';
import Footer from '../containers/Footer';

function ProductPage({location: { pathname }}) {
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return(
    <div>
      <Header />
      <Product />
      <Footer />
    </div>
  );
}

export default withRouter(ProductPage);
