import React from 'react';
//import ReactDOM from 'react-dom';
import { render } from 'react-snapshot';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import './css/imports.scss';
import HomePage from './js/components/pages/HomePage';
import AboutPage from './js/components/pages/AboutPage';
import MaterialPage from './js/components/pages/MaterialPage';
//import ProductListPage from './js/components/pages/ProductListPage';
import ProductPage from './js/components/pages/ProductPage';
import NewsPage from './js/components/pages/NewsPage';
import ArticlePage from './js/components/pages/ArticlePage';
import ContactPage from './js/components/pages/ContactPage';
import SiteMapPage from './js/components/pages/SiteMapPage';
import NoPageFound from './js/components/pages/NoPageFound';

render(
    <Router>
        <Switch>
            <Route exact path='/' component={HomePage}/>
            <Route exact path='/about' component={AboutPage}/>
            <Route exact path='/products' component={MaterialPage}/>
            <Route exact path='/products/:productName' component={ProductPage}/>
            <Route exact path='/news' component={NewsPage}/>
            <Route exact path='/news/:article/:articleID' component={ArticlePage}/>
            <Route exact path='/contact' component={ContactPage}/>
            <Route exact path='/sitemap' component={SiteMapPage}/>
            <Route component={NoPageFound}/>
        </Switch>
    </Router>,
   document.getElementById('root')
 );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
