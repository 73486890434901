import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

//components
import Header from '../containers/Header';
import Contact from '../containers/Contact';
import Footer from '../containers/Footer';

function ContactPage({location: { pathname }}) {
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return(
    <div>
      <Helmet>
          <title>TreeMaTech – Contact</title>
          <meta name="description" content="Invest in a future without plastic waste. Contact us"/>
          <meta property="og:title" content="TreeMaTech - Contact"/>
          <meta property="og:description" content="Invest in a future without plastic waste. Contact us"/>
          <meta property="og:url" content="https://treematech.com/contact"/>
          <meta property="og:image" content="https://treematech.com/og_bg.jpg"/>
      </Helmet>
      <Header />
      <Contact />
      <Footer />
    </div>
  );
}

export default withRouter(ContactPage);
